<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="superMemberships"
        :options.sync="pagination"
        :server-items-length="totalSuperMemberships"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <v-menu :close-on-content-click=false bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            >mdi-focus-field-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item >
              <v-list-item-title>{{ item.id }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.voucher="{ item }">
        {{item.voucher + "€"}}
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>

      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field v-model="shopId" type="text"
                          @keyup.enter="page = 1;retrieveSuperMemberships();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="shopName" type="text"
                          @keyup.enter="page = 1;retrieveSuperMemberships();"></v-text-field>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                  dark
                  @click="
                      page = 1;
                      retrieveSuperMemberships();
                    "
              >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                  dark
                  @click="
                  page = 1;
                  clearSearch();
                "
              >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'SuperMembershipCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import MembershipApiService from "@/core/services/api.service.membership";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  name : "super-memberships" ,
  computed : {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        { text : "Id" , align : "start" , value : "id" , sortable : false } ,
        { text : this.$i18n.t ( 'pages.membership.shopId' ) , value : "shopId" , sortable : false } ,
        { text : this.$i18n.t ( 'pages.membership.shopName' ) , value : "shopName" , sortable : false },
        { text : this.$i18n.t ( 'pages.membership.voucher' ) , value : "voucher" , sortable : false },
        {text: "", value: "clear", width: "5%", sortable: false},
        {text: "", value: "edit", width: "5%", sortable: false},
        {text: "", value: "add", width: "5%", sortable: false}
      ]
    }
  } ,
  data() {
    return {
      pageSizes : [10 , 20 , 50 , 100] ,
      totalSuperMemberships : 0 ,
      superMemberships : [] ,
      loading : false ,
      membershipApiInitiated : false ,
      pagination : {} ,
      loader : null ,
      errors : [],
      shopId: null,
      shopName: null
    };
  } ,
  watch : {
    pagination : {
      handler() {
        if (this.membershipApiInitiated) this.retrieveSuperMemberships ();
      } ,
      deep : true
    }
  } ,
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    await MembershipApiService.init ();
    this.membershipApiInitiated = true;
    this.retrieveSuperMemberships();
  } ,
  mounted() {
    this.$store.dispatch ( SET_BREADCRUMB , [{ title : "Super Memberships" }] );
  } ,
  components: {
    ConfirmDialog
  },
  methods : {
    async delRecord(item) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete super membership with id: " + item.id + " ?"
          )
      ) {
        this.deleteSuperMembership(item);
      }
    },
    deleteSuperMembership(item) {
      this.loading = true;
      if (item.id != null) {
        MembershipApiService.delete(`/Permissions/RemoveShopPermissionDefinitions?id=${item.id}`)
            .then(response => {
              this.$log.debug("Deleted super membership: ", item.id);
              this.$log.debug("Response: ", response);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => (this.retrieveSuperMemberships(), this.loading = false));
      }
    },
    getRequestParams(shopId, shopName) {

      let params = {};

      if (shopId) {
        params["shopId"] = shopId;
      }
      if (shopName) {
        params["shopName"] = shopName;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    } ,
    clearSearch() {
      this.shopId = null;
      this.shopName = null;
    } ,
    retrieveSuperMemberships() {
      const params = this.getRequestParams (this.shopId, this.shopName);
      this.$log.debug ( "params: " , params );
      return new Promise ( resolve => {
        this.loading = true;
        MembershipApiService.query ( "/Permissions/GetSuperMembershipShopPermissions" ,
            params
        ).then ( response => {
          // JSON responses are automatically parsed.
          this.$log.debug ( "Super memberships response: " , response.data );
          this.superMemberships = response.data.content;
          this.totalSuperMemberships = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalSuperMemberships;
          this.$store.dispatch ( SET_BREADCRUMB , [{ title : "Super Memberships: " + title }] );
          resolve ();
        } );
      } );
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
