var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.superMemberships,"options":_vm.pagination,"server-items-length":_vm.totalSuperMemberships,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-focus-field-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(item.id))])],1)],1)],1)]}},{key:"item.voucher",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucher + "€")+" ")]}},{key:"item.add",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;_vm.retrieveSuperMemberships();}},model:{value:(_vm.shopId),callback:function ($$v) {_vm.shopId=$$v},expression:"shopId"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;_vm.retrieveSuperMemberships();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.retrieveSuperMemberships();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'SuperMembershipCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,4161370050)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }